.react-datepicker__month-container {
    width: 75% !important;
}
.react-datepicker__time-container {
    width: 25% !important
}
@media screen and (max-width:375px){
    .react-datepicker__month-container, .react-datepicker__time-container {
        width: 100% !important;
    }
}
@media screen and (min-width:376px) {
    .react-datepicker__time-list-item--selected {
        width: 95% !important;
    }
}