.imageMask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  /* --rect-size: 300px; */

  /* clip-path: polygon(
    evenodd,
    0 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%,
    0 0,
    0% 6%,
    100% 6%,
    100% 6%,
    100% 95%,
    100% 95%,
    0% 95%,
    0% 95%,
    0% 6%
  ); */

  /* can cut through all this */
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background-image: url(https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png); */
  /* background-size: 40px; */
  /* -webkit-backdrop-filter: blur(3px); */
  /* backdrop-filter: blur(3px); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
