@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Mulish:wght@400;700;800&family=Rubik:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700;800&display=swap'); */

.containerHeight {
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
}

.overlay {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.css-gfghaq {
  margin-top: 0 !important;
  margin-bottom: 10px;
}

/*font for mfine*/

.poppins {
  font-family: 'Poppins', sans-serif;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

body {
  margin: 0 !important;
  padding: 0 !important;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #0058ff;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

.react-datepicker__month-container {
    width: 75% !important;
}
.react-datepicker__time-container {
    width: 25% !important
}
@media screen and (max-width:375px){
    .react-datepicker__month-container, .react-datepicker__time-container {
        width: 100% !important;
    }
}
@media screen and (min-width:376px) {
    .react-datepicker__time-list-item--selected {
        width: 95% !important;
    }
}
.element {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  /* --rect-size: 300px; */

  -webkit-clip-path: polygon(
    evenodd,
    0 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%,
    0 0,
    10% 5%,
    90% 5%,
    90% 5%,
    90% 40%,
    90% 40%,
    10% 40%,
    10% 40%,
    10% 5%
  );

          clip-path: polygon(
    evenodd,
    0 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%,
    0 0,
    10% 5%,
    90% 5%,
    90% 5%,
    90% 40%,
    90% 40%,
    10% 40%,
    10% 40%,
    10% 5%
  );

  /* can cut through all this */
  background-color: rgba(0, 0, 0, 0.5);
  /* background-image: url(https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png); */
  background-size: 40px;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.overlay {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.imageMask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  /* --rect-size: 300px; */

  /* clip-path: polygon(
    evenodd,
    0 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%,
    0 0,
    0% 6%,
    100% 6%,
    100% 6%,
    100% 95%,
    100% 95%,
    0% 95%,
    0% 95%,
    0% 6%
  ); */

  /* can cut through all this */
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background-image: url(https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png); */
  /* background-size: 40px; */
  /* -webkit-backdrop-filter: blur(3px); */
  /* backdrop-filter: blur(3px); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

video {
  border-radius: 7px;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #2297be;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

.element {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  /* --rect-size: 300px; */

  -webkit-clip-path: polygon(
    evenodd,
    0 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%,
    0 0,
    10% 5%,
    90% 5%,
    90% 5%,
    90% 40%,
    90% 40%,
    10% 40%,
    10% 40%,
    10% 5%
  );

          clip-path: polygon(
    evenodd,
    0 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%,
    0 0,
    10% 5%,
    90% 5%,
    90% 5%,
    90% 40%,
    90% 40%,
    10% 40%,
    10% 40%,
    10% 5%
  );

  /* can cut through all this */
  background-color: rgba(0, 0, 0, 0.5);
  /* background-image: url(https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png); */
  background-size: 40px;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.scannerParent section {
  height:85vh;
}
.overlay {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.imageMask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  /* --rect-size: 300px; */

  /* clip-path: polygon(
    evenodd,
    0 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%,
    0 0,
    0% 6%,
    100% 6%,
    100% 6%,
    100% 95%,
    100% 95%,
    0% 95%,
    0% 95%,
    0% 6%
  ); */

  /* can cut through all this */
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background-image: url(https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png); */
  /* background-size: 40px; */
  /* -webkit-backdrop-filter: blur(3px); */
  /* backdrop-filter: blur(3px); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #2297be;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

.element {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  /* --rect-size: 300px; */

  -webkit-clip-path: polygon(
    evenodd,
    0 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%,
    0 0,
    10% 5%,
    90% 5%,
    90% 5%,
    90% 40%,
    90% 40%,
    10% 40%,
    10% 40%,
    10% 5%
  );

          clip-path: polygon(
    evenodd,
    0 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%,
    0 0,
    10% 5%,
    90% 5%,
    90% 5%,
    90% 40%,
    90% 40%,
    10% 40%,
    10% 40%,
    10% 5%
  );

  /* can cut through all this */
  background-color: rgba(0, 0, 0, 0.5);
  /* background-image: url(https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png); */
  background-size: 40px;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.scannerParent section {
  height:85vh;
}
.overlay {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.imageMask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  /* --rect-size: 300px; */

  /* clip-path: polygon(
    evenodd,
    0 0,
    100% 0,
    100% 0,
    100% 100%,
    100% 100%,
    0 100%,
    0 100%,
    0 0,
    0% 6%,
    100% 6%,
    100% 6%,
    100% 95%,
    100% 95%,
    0% 95%,
    0% 95%,
    0% 6%
  ); */

  /* can cut through all this */
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background-image: url(https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png); */
  /* background-size: 40px; */
  /* -webkit-backdrop-filter: blur(3px); */
  /* backdrop-filter: blur(3px); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
}

