/* @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700;800&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Mulish:wght@400;700;800&family=Rubik:wght@300;400;500;700&display=swap');

.containerHeight {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.overlay {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.css-gfghaq {
  margin-top: 0 !important;
  margin-bottom: 10px;
}

/*font for mfine*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.poppins {
  font-family: 'Poppins', sans-serif;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

body {
  margin: 0 !important;
  padding: 0 !important;
}
